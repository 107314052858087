import state from "@/store/state";
import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  //设置默认路径,引入home页显示
  {
    path: "/",
    redirect: "home",
    meta: {
      title: state.en.titles.home_title,
    },
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/home"),
    meta: {
      title: state.en.titles.home_title,
    },
  },
  {
    path: "/login",
    name: "登录页",
    component: () => import("../views/login"),
    meta: {
      title: state.en.titles.home_title,
    },
  },
  {
    path: "/user",
    name: "用户中心-user",
    component: () => import("../views/user"),
    meta: {
      title: state.en.titles.home_title,
    },
  },
  {
    path: "/ifr",
    name: "嵌入页（游戏类）-ifr-game",
    component: () => import("../views/ifr"),
    meta: {
      title: state.en.titles.home_title,
    },
  },
  {
    path: "/ifrs",
    name: "嵌入页（游戏类）-ifr-game - 备用跳转 - spare",
    component: () => import("../views/ifrs"),
    meta: {
      title: state.en.titles.home_title,
    },
  },
  {
    path: "/platforms",
    name: "永乐体育-sports",
    component: () => import("../views/game/platforms"),
    meta: {
      title: state.en.titles.home_title,
    },
  },
  {
    path: "/vips",
    name: "vip",
    component: () => import("../views/vip"),
    meta: {
      title: state.en.titles.home_title,
    },
  },
  {
    path: "/discount",
    name: "优惠-discount",
    component: () => import("../views/discount"),
    meta: {
      title: state.en.titles.home_title,
    },
  },
  {
    path: "/register",
    name: "注册-register",
    component: () => import("../views/register"),
    meta: {
      title: state.en.titles.home_title,
    },
  },
  {
    path: "/forgot-password",
    name: "找回密码-recover password",
    component: () => import("../views/forgotPassword"),
    meta: {
      title: state.en.titles.home_title,
    },
  },
  {
    path: "/test",
    name: "test",
    component: () => import("../views/test"),
    meta: {
      title: state.en.titles.home_title,
    },
  },
  {
    path: "/betting-responsibility",
    name: "Betting Responsibility",
    component: () => import("../views/info/bettingResponsibility"),
    meta: {
      title: state.en.titles.home_title,
    },
  },
  {
    path: "/terms-and-conditions",
    name: "Terms and Conditions",
    component: () => import("../views/info/termsAndConditions"),
    meta: {
      title: state.en.titles.home_title,
    },
  },
  {
    path: "/contact-us",
    name: "/contact-us",
    component: () => import("../views/info/contactUs"),
    meta: {
      title: state.en.titles.home_title,
    },
  },
  {
    path: "/privacy-protection",
    name: "privacy and protection",
    component: () => import("../views/info/privacyProtection"),
    meta: {
      title: state.en.titles.home_title,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
