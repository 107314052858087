<template>
  <div class="head-main f-c">
    <header class="head">
      <div class="f-c">
        <router-link to="/">
          <img
            title="永乐娱乐"
            :alt="$en('home.search')"
            class="nav-logo"
            src="../assets/logo.png"
          />
        </router-link>
      </div>
      <div class="f-c">
        <div
          class="nav f-c nav-re"
          v-for="(item, index) in $store.state.appIndex"
          :key="index"
        >
          <div
            @click="$store.state.platform = item.gameType"
            :class="
              $store.state.platform == item.gameType ? 'active-nav' : 'nav-text'
            "
          >
            <router-link :to="linkFx(item)" class="nav-text">
              <div
                @mouseover="
                  startIndex = 0;
                  endIndex = 3;
                "
              >
                {{ item.gameTypeName }}
              </div>
            </router-link>
          </div>
          <div
            class="nav-list"
            v-loading="loadingNav"
            element-loading-text="加载中.."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 0.8)"
          >
            <img
              src="../assets/image/leftArrow.png"
              class="filterd-icon"
              style="width: 60px; height: 60px; cursor: pointer"
              @click="prevPage(item.platforms)"
            />
            <div style="width: 1600px" class="f-c">
              <div
                class="nav-list-img"
                v-for="(v, n) in item.platforms.slice(startIndex, endIndex)"
                :key="n"
              >
                <img
                  class="gameImg"
                  :src="$store.state.iconUrl + v.pcStatic.entranceImage"
                  @load="gameImgLoaded = true"
                  style="display: none"
                />
                <div class="gameImg-main">
                  <img
                    class="gameImg"
                    src="../assets/loading.gif"
                    v-if="!gameImgLoaded"
                  />
                  <div class="platformImgContainer" @click="$goGame(v)">
                    <img
                      class="gameImg"
                      :src="$store.state.iconUrl + v.pcStatic.entranceImage"
                      v-if="gameImgLoaded"
                    />
                    <div class="img-info">
                      <img :src="$store.state.iconUrl + v.platformLogo" />
                      <div class="gamename">{{ v.platformName }}</div>
                      <div class="play">{{ $en("games.enter") }}</div>
                    </div>
                  </div>
                  <div
                    class="error"
                    v-show="
                      $store.state.gameError.some((e) => e === v.uniqueCode)
                    "
                  >
                    <div>
                      {{ $en("general.maintenance") }}
                    </div>
                    <img src="../assets/image/maintainaceLogo.png" />
                  </div>
                </div>
              </div>
            </div>
            <img
              src="../assets/image/rightArrow.png"
              class="filterd-icon"
              style="width: 60px; height: 60px; cursor: pointer"
              @click="nextPage(item.platforms)"
            />
          </div>
        </div>
      </div>
      <!-- vip -->
      <div class="f-c">
        <div class="nav-bor" style="padding-right: 24px">
          <div
            class="nav f-c"
            style="position: relative"
            @click="showLangPopup = !showLangPopup"
          >
            <img src="../assets/image/langIcon.png" />
            {{ $en("general.lang") }}
            <div class="lang-container" v-if="showLangPopup">
              <div
                class="lang"
                v-for="(lang, index) in languages"
                :key="index"
                @click="setLang(lang.code)"
              >
                <div
                  class="lang_text"
                  :style="{ fontWeight: selectedLang === lang.code && 'bold' }"
                >
                  <img class="flag" :src="lang.img" />
                  {{ lang.text }}
                </div>
                <img
                  src="../assets/image/selectLang.png"
                  alt=""
                  style="width: 16px; height: 12px"
                  v-if="selectedLang === lang.code"
                />
              </div>
            </div>
          </div>
          <div
            class="nav f-c"
            @click="
              classBtn('invitation');
              $store.state.sharePage = false;
            "
          >
            <img src="../assets/image/invitation.png" />
            {{ $en("let_us_invite.invite") }}
          </div>
          <router-link class="nav f-c" to="/discount" active-class="active-nav">
            <img src="../assets/image/nav1.png" />
            {{ $en("discount.title") }}
          </router-link>
          <router-link class="nav f-c" to="/vips" active-class="active-nav">
            <img src="../assets/image/vip.png" />
            {{ $en("vip.title") }}
          </router-link>
        </div>
        <div
          class="f-c none"
          style="cursor: pointer"
          v-if="$store.state.userInfo"
        >
          <div class="f-c a-color" @click="userFx">
            <div class="f-c avatar">
              <img
                :src="
                  $store.state.iconUrl +
                  ($store.state.static.avatar[$store.state.userInfo.userIcon] ||
                    $store.state.static.headIcon[
                      $store.state.userInfo.userIcon
                    ])
                "
              />
            </div>
            <div :class="{ avt_text: $route.path === '/user' }">
              <div class="f-s">
                <b class="name">{{ $store.state.userInfo.username }}</b>
              </div>
              <div class="nav-money">
                {{ $store.state.money || 0 }}¥
                <div class="f-c" style="margin-left: 8px">
                  vip {{ $store.state.userInfo.userLevel }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="display: flex" v-else>
          <div class="f-c login">
            <router-link
              class="login-btn"
              to="/login"
              active-class="active-nav"
              >{{ $en("register.log_in") }}</router-link
            >
          </div>
          <div class="f-c login">
            <router-link
              class="login-btn"
              to="/register"
              active-class="active-nav"
              >{{ $en("register.title") }}</router-link
            >
          </div>
        </div>
      </div>
    </header>
    <div class="modal" v-if="$store.state.card">
      <div class="modal_content">
        <div class="header">
          <div>{{ $en("popups.imp_notice") }}</div>
          <img
            src="../assets/image/modalCrossIcon.png"
            @click="$store.state.card = false"
          />
        </div>
        <div>{{ $en("popups.bonus_venue") }}</div>
        <div style="font-size: 18px; font-weight: bold; margin: 13px 0">
          {{ integerPart() }}.<span style="font-size: 14px">{{
            decimalPart()
          }}</span>
        </div>
        <div style="width: 276px">
          {{ $en("popups.turnover_question") }}
        </div>
        <div class="input">
          <div>¥</div>
          <input
            type="text"
            :placeholder="$en('inputs.amount')"
            v-model="$store.state.formData.amount"
            @blur="okformdata"
            oninput="this.value = this.value.replace(/[^0-9.]/g,'')"
          />
          <button @click="moneyMax(all)">{{ $en("popups.maximum") }}</button>
        </div>
        <div class="btns">
          <button style="background: #6b6b6b" @click="Gbalance(0)">
            {{ $en("popups.enter_directly") }}
          </button>
          <button @click="Gbalance(1)">
            {{ $en("popups.transfer_balance") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RouterLink } from "vue-router";
import state from "@/store/state";
import { gameBalancegetNewestBalance, gameLoginpcLogin } from "../api/galApi";

export default {
  dicts: [],
  props: [],
  data() {
    return {
      newestBalance: 0,
      all: 1,
      total_balance: "",
      loadingNav: false,
      link: "",
      showLangPopup: false,
      gameImgLoaded: false,
      languages: [
        {
          img: require("../assets/image/langFlag4.png"),
          text: state.en.languages.lang4,
          code: "tc",
        },
        {
          img: require("../assets/image/langFlag2.png"),
          text: state.en.languages.lang2,
          code: "cn",
        },
        {
          img: require("../assets/image/langFlag1.png"),
          text: state.en.languages.lang1,
          code: "en",
        },
      ],
      selectedLang: localStorage.getItem("lang") || "tc",
      startIndex: 0,
      itemsPerPage: 3,
    };
  },
  mounted() {
    if (this.$logins("no")) {
      this.$money();
    }
    document.addEventListener("click", this.onClickOutside);
  },
  beforeDestroy() {
    // Remove the event listener when the component is destroyed
    document.removeEventListener("click", this.onClickOutside);
  },
  computed: {
    endIndex() {
      return this.startIndex + this.itemsPerPage;
    },
  },
  methods: {
    classBtn(val) {
      this.$router.push("/user");
      state.navIcon = val;
      localStorage.setItem("navIcon", state.navIcon);
    },
    nextPage(items) {
      if (this.endIndex < items.length) {
        this.startIndex += 1;
      } else {
        this.startIndex = 0;
        this.endIndex = 3;
      }
    },
    prevPage(items) {
      if (this.startIndex > 0) {
        this.startIndex -= 1;
      } else {
        this.startIndex = items.length - 3;
        this.endIndex = 0;
      }
    },
    setLang(lang) {
      localStorage.setItem("lang", lang);
      this.$router.push("/");
      setTimeout(() => {
        window.location.reload();
      }, 100);
      localStorage.removeItem("appIndex");
      localStorage.removeItem("staticResources");
      localStorage.removeItem("game");
    },
    onClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.showLangPopup = false;
      }
    },
    integerPart() {
      const integer = parseInt(state.total_balance);
      return isNaN(integer) ? "" : integer;
    },
    decimalPart() {
      const decimal = parseFloat(state.total_balance).toFixed(2);
      return isNaN(decimal) ? "" : decimal.slice(-2);
    },

    linkFx() {
      return (this.link = "/platforms");
    },
    userFx() {
      this.$router.push("/user");
      state.navIcon = "purse";
      localStorage.setItem("navIcon", "purse");
    },
    gameBtn(data) {
      let that = this;
      if (that.$logins()) {
        that.loadingNav = true;
        setTimeout(function () {
          that.loadingNav = false;
        }, 1500);
        this.openGame(data.uniqueCode);
      }
    },
    openGame(uniqueCode) {
      gameLoginpcLogin(uniqueCode).then((res) => {
        let that = this;
        //gameError
        if (res.data.allGamePlatform) {
          that.$gameError(res.data.allGamePlatform);
        }
        // alert
        if (res.data.loginResultType == 1) {
          that.$router.push({
            path: "/platforms",
            query: {
              code: uniqueCode,
            },
          });
          return;
        }
        if (res.data.loginResultType == 3) {
          state.unique = uniqueCode;
          state.card = true;
          state.formData.amount = "";
          state.total_balance = res.data.loginResultData;
          return;
        } else if (res.data.loginResultData) {
          // open - game - link
          localStorage.setItem("ifrUrl", res.data.loginResultData);
          if (that.$route.path == "/ifr") {
            that.$router.push("/ifrs"); //备用跳转 -spare
          } else {
            that.$router.push("/ifr");
          }
        } else {
          that.$codes(state.codes[res.code], 2);
        }
      });
    },
    Gbalance(e) {
      this.$gamebalance(e);
    },
    moneyMax(e) {
      gameBalancegetNewestBalance(0).then((res) => {
        if (e == 1) {
          this.$store.state.formData.amount = res.data.newestBalance;
        }
        this.newestBalance = res.data.newestBalance;
      });
    },
    okformdata(e) {
      let _this = this;
      _this.$store.state.formData[e.currentTarget.dataset.type] =
        e.detail.value;

      if (this.$store.state.formData.amount == "") {
        // this.$message.error("您的余额不足。");
        this.$codes(state.en.alerts.insufficient_money, 2);
        return;
      }
    },
  },
  components: { RouterLink },
};
</script>

<style scoped lang="scss">
.head-main {
  padding-top: 64px;
}
.head {
  display: flex;
  align-items: end;
  justify-content: space-between;
  max-width: 1920px;
  padding: 0 70px;
  position: fixed;
  top: 0;
  z-index: 3001;
  width: 100%;
  height: 64px;
  background: #f7f7f7;
  user-select: none;
}
.head .nav {
  height: 50px;
  padding: 0 12px;
  cursor: pointer;
  color: #003b3d;
  text-decoration: none;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.lang-container {
  width: 216px;
  background-color: #c4c4c4;
  color: #003b3d;
  border-radius: 20px;
  position: absolute;
  top: 42px;
  left: -100px;
  padding: 0 14px;
  font-size: 12px;
  .lang {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 12px;
    border-bottom: 1px solid #003b3d;
    .lang_text {
      display: flex;
      align-items: center;
      gap: 8px;
      .flag {
        width: 24px;
        height: 17px;
      }
    }
  }
  .lang:last-child {
    border: none;
  }
}
.nav-text {
  font-size: 16px;
  color: #003b3d;
  text-decoration: none;
}
.head .nav-text:hover {
  border-bottom: 3px solid #444750;
  padding-bottom: 18px;
  padding-top: 10px;
}
.active-nav {
  height: 50px;
  border-bottom: 3px solid #444750;
  transition: all ease-out 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-logo {
  height: 47px;
  width: 165px;
}
.nav-bor {
  border-right: 2px solid #444750;
  font-size: 14px;
  white-space: nowrap;
  display: flex;
  img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}
.login {
  width: 85px;
  height: 31px;
  margin: 0 10px;
  border: 2px solid #003b3d;
  border-radius: 20px;
}
.login-btn {
  color: #003b3d;
  font-size: 14px;
  width: 85px;
  height: 31px;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}
.login-btn:hover {
  color: white;
  background: #003b3d;
}
.nav-re {
  position: relative;
}
.nav-re:hover .nav-list {
  display: flex;
  @keyframes move {
    0% {
      top: -20px;
      opacity: 0.1;
    }
    100% {
      top: 64px;
      opacity: 1;
    }
  }
}
.nav-list {
  // height: 300px;
  // background-color: rgba(255, 255, 255, 0.7);
  padding: 40px 0;
  background-color: rgba(77, 77, 77, 0.9);
  position: fixed;
  z-index: 102;
  left: 0;
  top: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 1920px;
  // gap: 95px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  width: 100%;
  display: none;
  cursor: auto;
  animation-name: move;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
.nav-list .img {
  width: 429px;
  height: 231px;
}
.nav-list-img {
  .gameImg {
    cursor: pointer;
    height: 100%;
    width: 100%;
  }
  .platformImgContainer {
    width: 430px;
    height: 220px;
    margin: 0 45px;
    position: relative;
    .img-info {
      position: absolute;
      cursor: pointer;
      left: 24px;
      top: 44px;
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 8px;
      img {
        height: 40px;
        object-fit: contain;
      }
      .gamename {
        color: #3c3c3c;
        font-size: 22px;
        font-weight: bold;
      }
      .play {
        background: white;
        color: #003b3d;
        font-size: 22px;
        font-weight: bold;
        height: 48px;
        width: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.blur {
  filter: blur(4px);
}
.gameImg-main {
  position: relative;
}
.gameImg-main .error {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  top: 0;
  z-index: 5;
  width: 430px;
  height: 220px;
  background: rgba(161, 161, 161, 0.5);
  font-weight: bold;
  backdrop-filter: blur(5px);
  margin: auto 45px;
  img {
    width: 128.675px;
    height: 101.739px;
  }
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  margin: 0 12px 0 24px;
  img {
    width: 100%;
    height: 100%;
  }
}
.avt_text {
  border-bottom: 3px solid #444750;
  transition: all ease-out 0.2s;
}
.name {
  font-size: 14px;
  margin-right: 5px;
}
.nav-money {
  font-size: 12px;
  color: #4d4d4d;
  display: flex;
}
.vip {
  color: #7b7b7b;
  font-size: 14px;
  background: #d9d9d9;
  border-radius: 2px;
  padding: 1px 4px;
}
.a-color {
  text-decoration: none;
  color: #003b3d;
}
.modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 5000;
  font-size: 14px;
  color: #6b6b6b;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  .modal_content {
    width: 568px;
    padding: 25px 36px;
    background: #cbcbcb;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 14px;
    .header {
      color: #6b6b6b;
      font-size: 28px;
      line-height: 28px;
      font-weight: bold;
      margin-bottom: 44px;
      white-space: nowrap;
      position: relative;
      width: 100%;
      img {
        height: 24px;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    .input {
      display: flex;
      align-items: center;
      gap: 19px;
      margin-top: 25px;
      width: 364px;
      height: 52px;
      background: #bdc1c7;
      color: #6b6b6b;
      font-size: 28px;
      line-height: 28px;
      font-weight: bold;
      padding: 0 14px;
      input {
        border: none;
        outline: none;
        background: transparent;
        height: 100%;
        flex: 1;
      }
      button {
        width: 117px;
        height: 38px;
        background: #6b6b6b;
        border: none;
        color: white;
        cursor: pointer;
      }
    }
  }
  .btns {
    display: flex;
    align-items: center;
    gap: 21px;
    margin-top: 31px;
    button {
      width: 111px;
      height: 38px;
      background: #008d91;
      border: none;
      color: white;
      cursor: pointer;
    }
  }
}
</style>
